import React from 'react'
import './Privacy.css'

const Privacy = () => {
    return (
        <div className='privacy-container'>
        <div className='privacy'>
            <h1>Privacy Policy</h1>
            <p><strong>Last Updated:</strong> 12/17/23</p>
            <p>Welcome to Intelligent Sensing Labs! We are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information and information about your Sense One systems when you use our website at <a href="http://www.intelligentsensinglabs.com">www.intelligentsensinglabs.com</a> ("Site") and our products and services.</p>
            
            <div className="section">
            <div className="divider"></div>
                <h2>1. Information We Collect</h2>
                <h3>1.1 Personal Information:</h3>
                <p>We may collect personal information such as your name, email address, and phone number when you voluntarily provide it to us, for example, when you subscribe to our services, fill out forms on our Site, or contact us.</p>
            </div>

            <h3>1.2 Device Information:</h3>
            <p>We may collect information about your device, including its unique device identifier, operating system, browser type, and IP address.</p>
            
            <h3>1.3 Usage Information:</h3>
            <p>We may collect information about how you use our Site, products, and services, including pages visited, features used, and interactions.</p>
            
            <h3>1.4 Sense One System Information:</h3>

            <p>We collect information about your Sense One system(s), including data from connected sensors, UUIDs, and serial numbers.</p>
            
            <div className="section">
            <div className="divider"></div>
            <h2>2. How We Use Your Information</h2>
            <h3>2.1 Providing Services:</h3>
            <p>We use your personal information to provide and deliver our products and services, process transactions, and communicate with you.</p>

            <h3>2.2 Improving Services:</h3>
            <p>We analyze usage data, including Sense One system information, to improve our Site, products, and services, including troubleshooting issues and optimizing user experience.</p>
            
            <h3>2.3 Sense One System Data:</h3>
            <p>We use information about your Sense One systems to ensure proper functioning, provide technical support, and enhance the capabilities of our products and services.</p>
            </div>

            <div className="section">
            <div className="divider"></div>
            <h2>3. Information Sharing</h2>
            <h3>3.1 Third-Party Service Providers:</h3>
            <p>We may share your information, including Sense One system data, with third-party service providers who assist us in operating our business, conducting analysis, or servicing you.</p>
            
            <h3>3.2 Legal Compliance:</h3>
            <p>We may disclose your information to comply with legal obligations, enforce our policies, or respond to lawful requests from public authorities.</p>
            </div>

            <div className="section">
            <div className="divider"></div>
            <h2>4. Data Security</h2>
            <h3>4.1 Security Measures:</h3>
            <p>We take reasonable measures to protect your information, including Sense One system data, from unauthorized access, disclosure, alteration, or destruction.</p>
            
            <h3>4.2 Data Retention:</h3>
            <p>We retain your personal information and Sense One system data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required by law.</p>
            </div>

            <div className="section">
            <div className="divider"></div>
            <h2>5. Your Choices</h2>
            <h3>5.1 Opt-Out:</h3>
            <p>You can opt-out of receiving promotional communications from us by following the instructions in the communication.</p>
            
            <h3>5.2 Access and Correction:</h3>
            <p>You have the right to access and correct your personal information. Contact us at <a href="mailto:amalinouski@intelligentsensinglabs.com">amalinouski@intelligentsensinglabs.com</a> for assistance.</p>
            </div>

            <div className="section">
            <div className="divider"></div>
            <h2>6. Changes to Privacy Policy</h2>
            <h3>6.1 Updates:</h3>
            <p>We may update this Privacy Policy from time to time. The date of the latest update will be displayed at the top of this page.</p>
        
            <h3>6.2 Notice:</h3>
            <p>We will notify you of significant changes to this Privacy Policy through the Site or via email.</p>
            </div>

            <div className="section">
            <div className="divider"></div>
            <h2>7. Contact Information</h2>
            <p>For any questions or concerns regarding this Privacy Policy, please reach out to us at:</p>
            <address>
                <p>Intelligent Sensing Labs</p>
                <p>399 Congress St., #223</p>
                <p>Boston, Massachusetts</p>
            </address>
            <p><a href="mailto:amalinouski@intelligentsensinglabs.com">amalinouski@intelligentsensinglabs.com</a></p>
            <p><a href="mailto:rberube@intelligentsensinglabs.com">rberube@intelligentsensinglabs.com</a></p>
            <div className='divider'></div>
            <p>Thank you for trusting Intelligent Sensing Labs with your information!</p>
            </div>
        </div> 
        </div>        
    )
}

export default Privacy