// cookieUtils.js

export const setCookie = (name, value, daysToLive) => {
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() + (daysToLive * 24 * 60 * 60 * 1000));
    const expires = "expires=" + expiryDate.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };
  
  export const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length);
    }
    return null;
  };

  export const getUserIdentifier = () => {
    const userIdCookie = getCookie('userId');
    return userIdCookie || 'default';
  };