import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Hero from './Hero';
import Dashboard from './Dashboard';
import Register from './Register';
import Products from './Products';
import Profile from './Profile';
import Footer from './Footer';
import Privacy from './Privacy';
import Terms from './Terms';
import ChangePassword from './ChangePassword';
import CookieWarning from './CookieWarning';
import { getCookie, setCookie } from './cookieUtils'

const AppRoutes = ({ userData, setUserData, hasAcceptedCookies, handleAcceptCookies, handleLogin, handleRegistration, handlePasswordChange, isLocal }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem('currentPath', location.pathname);
  }, [location]);

  useEffect(() => {
    const savedPath = localStorage.getItem('currentPath');
    if (savedPath) {
      navigate(savedPath);
    }
  }, [navigate]);

  return (
    <div className="app">
      {!hasAcceptedCookies && <CookieWarning onAccept={handleAcceptCookies} />}
      <Navbar userData={userData} setUserData={setUserData} />
      <Routes>
        <Route path="/" element={<Home onLogin={handleLogin} />} />
        <Route path="/hero" element={userData ? <Hero userData={userData}/> : <Navigate to="/" replace />} />
        <Route path="/register" element={<Register onRegister={handleRegistration} />} />
        <Route path="/dashboard" element={userData ? <Dashboard userData={userData} isLocal={isLocal}/> : <Navigate to="/" replace />} />
        <Route path="/profile" element={userData ? <Profile userData={userData} /> : <Navigate to="/" replace />} />
        <Route path="/products" element={<Products />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/changepassword" element={<ChangePassword onChangePassword={handlePasswordChange} userData={userData}/>} />
      </Routes>
      <Footer />
    </div>
  );
};

export default AppRoutes;
