import React, { useState } from 'react';
import { Input, Row, Col, Modal } from 'antd';

const SensorManager = ({ activeSensors, onSetSensorPrefs }) => {
  const [temporaryValues, setTemporaryValues] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentSensorKey, setCurrentSensorKey] = useState('');
  const [currentField, setCurrentField] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleIntervalClick = (sensorKey, field) => {
    setCurrentSensorKey(sensorKey);
    setCurrentField(field);
    setInputValue(activeSensors[sensorKey][field]);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    const sanitizedValue = parseFloat(inputValue);
    if (!isNaN(sanitizedValue)) {
      let updatedMetadata = { ...activeSensors };
      updatedMetadata[currentSensorKey] = {
        ...updatedMetadata[currentSensorKey],
        [currentField]: sanitizedValue
      };
      setTemporaryValues(prev => ({
        ...prev,
        [currentSensorKey]: {
          [currentField]: sanitizedValue
        }
      }));
      onSetSensorPrefs(updatedMetadata);
      setIsModalVisible(false);
    } else {
      alert("Invalid input: not a number");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <h2 className="welcome-heading">Sensors</h2>
      <Row gutter={24} style={{ marginBottom: '5px' }}>
        <Col xs={4} lg={4}><h4>Device</h4></Col>
        <Col xs={8} lg={7}><h4>Measurement</h4></Col>
        <Col xs={6} lg={5}><h4>Sample(s)</h4></Col>
        <Col xs={2} lg={2}><h4>Per</h4></Col>
      </Row>
      {Object.entries(activeSensors).map(([sensorKey, sensor]) => (
        <Row key={sensorKey} gutter={24} style={{ marginBottom: '5px' }}>
          <Col xs={4} lg={4}>{sensor.device}</Col>
          <Col xs={8} lg={7}>{sensor.measurement}</Col>
          <Col xs={6} lg={5} onClick={() => handleIntervalClick(sensorKey, 'poll_interval')}>
            <span style={{ cursor: 'pointer', color: '#1890ff' }}>
              {temporaryValues[sensorKey]?.poll_interval || activeSensors[sensorKey].poll_interval}
            </span>
          </Col>
          <Col span={2}>second</Col>
        </Row>
      ))}

      <Modal
        title="Edit Interval"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onPressEnter={handleModalOk}
        />
      </Modal>
    </div>
  );
};

export default SensorManager;
