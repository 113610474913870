import React from 'react'
import './Privacy.css'

const Terms = () => {
    return (
        <div className='privacy-container'>
        <div className="privacy">
        <h1>Terms of Service</h1>
        <p><strong>Last Updated:</strong> 12/17/23</p>
        <p>Welcome to Intelligent Sensing Labs! Please carefully review these Terms of Service ("Terms") as they govern your use of our website at <a href="http://www.intelligentsensinglabs.com">www.intelligentsensinglabs.com</a> ("Site") and the products and services we offer.</p>
        <p>By accessing or using our Site, products, or services, you are acknowledging that you have read, understood, and agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using our Site, products, or services.</p>
      
        <div className="section">
          <h2>1. Subscription Services</h2>
          <div className="divider"></div>
          <h3>1.1 Subscription Plans:</h3>
          <p>Intelligent Sensing Labs offers a range of subscription services granting access to premium content, exclusive features, and other benefits.</p>
      
          <h3>1.2 Payment:</h3>
          <p>By subscribing to our services, you are agreeing to pay the specified subscription fees at the intervals defined in your chosen plan.</p>
      
          <h3>1.3 Automatic Renewal:</h3>
          <p>Subscription plans may automatically renew unless canceled before the renewal date. You can manage your subscription settings through your account.</p>
        </div>
      
        <div className="section">
          <h2>2. Product Pricing</h2>
          <div className="divider"></div>
          <h3>2.1 Flat Rate Pricing:</h3>
          <p>Our products are priced at flat rates, clearly indicated on the Site. While pricing is subject to change, any modifications will not impact orders already placed.</p>
      
          <h3>2.2 Taxes and Fees:</h3>
          <p>Prices do not include applicable taxes or fees, which will be added to your order total as required by law.</p>
        </div>
      
        <div className="section">
          <h2>3. Customer Data</h2>
          <div className="divider"></div>
          <h3>3.1 Data Collection:</h3>
          <p>Intelligent Sensing Labs collects customer data to facilitate the provision and improvement of our products and services. This may include personal information, which is handled in accordance with our Privacy Policy.</p>
      
          <h3>3.2 Security:</h3>
          <p>We prioritize the security of customer data. All data is stored securely, and robust measures are in place to guard against unauthorized access, disclosure, or alteration.</p>
        </div>

       <div className="section">
            <h2>4. User Responsibilities</h2>
            <div className="divider"></div>
            <h3>4.1 Account Information:</h3>
            <p>You are responsible for maintaining the confidentiality of your account information and for all activities conducted under your account.</p>

            <h3>4.2 Compliance:</h3>
            <p>Users are obligated to comply with all applicable laws and regulations while using our Site, products, and services.</p>
        </div>

        <div className="section">
            <h2>5. Intellectual Property</h2>
            <div className="divider"></div>
            <h3>5.1 Ownership:</h3>
            <p>All content, including but not limited to text, graphics, logos, images, and software, available on the Site or through our products and services, is the property of Intelligent Sensing Labs and is protected by intellectual property laws.</p>

            <h3>5.2 License:</h3>
            <p>By using our Site, products, or services, you are granted a limited, non-exclusive, non-transferable license to access and use the content for personal and non-commercial purposes.</p>

            <h3>5.3 Prohibited Activities:</h3>
            <p>You may not reproduce, distribute, modify, create derivative works, publicly display, publicly perform, republish, download, store, or transmit any of the content on our Site without the prior written consent of Intelligent Sensing Labs.</p>
        </div>

        <div className="section">
            <h2>6. Use of Sensor Data for Analytics</h2>
            <div className="divider"></div>
            <h3>6.1 Data Utilization:</h3>
            <p>Intelligent Sensing Labs reserves the right to use user sensor data, exclusively collected for analytics purposes, to enhance and optimize our products and services.</p>

            <h3>6.2 Anonymization:</h3>
            <p>User sensor data used for analytics will be anonymized and aggregated whenever possible to protect user privacy.</p>
        </div>

        <div className="section">
            <h2>7. Prohibition of Tampering</h2>
            <div className="divider"></div>
            <h3>7.1 Server Backend:</h3>
            <p>Users are expressly prohibited from attempting to tamper with the Intelligent Sensing Labs server backend by sending data pretending to be a ModulSense hardware system.</p>

            <h3>7.2 Unauthorized Access:</h3>
            <p>Any unauthorized attempts to manipulate or interfere with the server backend will result in immediate termination of access to our services and may be subject to legal action.</p>
        </div>

        <div className="section">
            <h2>8. Termination</h2>
            <div className="divider"></div>
            <h3>8.1 Termination by User:</h3>
            <p>You have the option to terminate your subscription or account at any time by following the instructions available on the Site.</p>

            <h3>8.2 Termination by Intelligent Sensing Labs:</h3>
            <p>We reserve the right to terminate or suspend your account or access to our services at our discretion, without prior notice, for any reason.</p>
        </div>

        <div className="section">
            <h2>9. Changes to Terms</h2>
            <div className="divider"></div>
            <h3>9.1 Modification:</h3>
            <p>Intelligent Sensing Labs reserves the right to modify or update these Terms at any time. The date of the latest update will be displayed prominently on this page.</p>

            <h3>9.2 Notice:</h3>
            <p>We will make reasonable efforts to notify users of significant changes to these Terms through the Site or via email.</p>
        </div>

        <div className="section">
            <h2>10. Contact Information</h2>
            <div className="divider"></div>
            <p>For any questions or concerns regarding these Terms of Service, please reach out to us at:</p>
            <address>
            <p>Intelligent Sensing Labs</p>
            <p>399 Congress St., #223</p>
            <p>Boston, Massachusetts</p>
            </address>
            <p><a href="mailto:amalinouski@intelligentsensinglabs.com">amalinouski@intelligentsensinglabs.com</a></p>
            <p><a href="mailto:rberube@intelligentsensinglabs.com">rberube@intelligentsensinglabs.com</a></p>
        </div>
    </div>
    </div>
    )
}

export default Terms