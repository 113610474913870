import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import AppRoutes from './AppRoutes';
import { getCookie, setCookie } from './cookieUtils';

function App() {
  const [userData, setUserData] = useState(() => {
    const savedUserData = localStorage.getItem('userData');
    return savedUserData ? JSON.parse(savedUserData) : null;
  });
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(false);
  const [isLocal, setIsLocal] = useState(false); // Set this to true for on-hub

  useEffect(() => {
    const cookieConsent = getCookie('cookieConsent');
    setHasAcceptedCookies(cookieConsent === 'true');
  }, []);

  const handleAcceptCookies = () => {
    setCookie('cookieConsent', 'true', 365);
    setHasAcceptedCookies(true);
  };

  let baseURL = 'https://intelligentsensinglabs.com/auth';
  if (isLocal) {
    baseURL = 'http://localhost:3030/auth';
  }
  const registerURL = `${baseURL}/register`;
  const changePasswordURL = `${baseURL}/changepassword`;
  const loginURL = `${baseURL}/login`;

  const handleLogin = async (email, password) => {
    try {
      const response = await fetch(loginURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data.user);
        setCookie('userId', data.user._id, 7); // TODO: Hash user id on the backend
        return { data: data.user };
      } else {
        const errorData = await response.json();
        console.error('APP: Login error:', errorData.message);
        return { error: errorData.message };
      }
    } catch (error) {
      console.error('APP: Error during login:', error.message);
      return { error: error.message };
    }
  };

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    } else {
      localStorage.removeItem('userData');
    }
  }, [userData]);

  const handleRegistration = async (userData) => {
    try {
      const response = await fetch(registerURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('APP: Registration successful', data);
        delete userData['password'];
        setUserData(userData);
        setCookie('userId', userData._id, 7);
        return { data: userData }; // Return an object with a data key
      } else {
        const errorData = await response.json();
        console.error('APP: Registration error:', errorData.message);
        return { error: errorData.message }; // Return an object with an error key
      }
    } catch (error) {
      console.error('APP: Error during registration:', error.message);
      return { error: error.message }; // Return an object with an error key
    }
  };

  const handlePasswordChange = async (userData) => {
    try {
      const response = await fetch(changePasswordURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        const data = await response.json();
        return { data: data }; // Return an object with a data key
      } else {
        const errorData = await response.json();
        return { error: errorData.message }; // Return an object with an error key
      }
    } catch (error) {
      console.error('APP: Error during password change:', error.message);
      return { error: error.message }; // Return an object with an error key
    }
  };

  return (
    <Router>
      <AppRoutes
        userData={userData}
        setUserData={setUserData}
        hasAcceptedCookies={hasAcceptedCookies}
        handleAcceptCookies={handleAcceptCookies}
        handleLogin={handleLogin}
        handleRegistration={handleRegistration}
        handlePasswordChange={handlePasswordChange}
        isLocal={isLocal}
      />
    </Router>
  );
}

export default App;
