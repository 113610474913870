// Home.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = ({ onLogin }) => {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Email validation regex
  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleLoginFormSubmit = async (e) => {
    e.preventDefault();
    
    // Trim and validate email
    const sanitizedEmail = email.trim();
    const sanitizedPassword = password.trim();

    if (!validateEmail(sanitizedEmail)) {
      setLoginError('Invalid email format');
      return;
    }

    const loginResponse = await onLogin(sanitizedEmail, sanitizedPassword);

    if (loginResponse.error) {
      setLoginError(loginResponse.error);
      console.log('Login failed:', loginResponse.error);
    } else {
      setLoginError(null);
      console.log('Login successful:', loginResponse.data);
      navigate('/dashboard', { state: { userData: loginResponse.data } });
    }
  }

  return (
    <div className="home">
      <div className='container'>
        <div className='content'>
          <div className='col-1'>
            <h1>Data collection </h1>
            <h1>
              <span className='primary-color'>reimagined.</span>
            </h1>
            <p>
              Intelligent Sensing Labs is designing a next-generation, connected sensor suite that simplifies data
              collection and analytics, turbocharging hardware teams and allowing engineers to focus on designing
              products, not test equipment.
            </p>
            <button className='learn-more-btn' onClick={() => navigate('/products')}>Learn More</button>
          </div>
          <div className='col-2'>
            <div className='form-layout'>
              <div className='form-container'>
                <div className='divider'>
                  <p className='sign-in-txt'>
                    <span>Beta access</span>
                  </p>
                </div>
                {loginError && (<div className="error-message">{loginError}</div>)}
                <form onSubmit={handleLoginFormSubmit}>
                  <input type='text' name='email' placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
                  <input type='password' name='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
                  <button type='submit'>Login</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
