import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Register.css';

const Register = ({ onRegister }) => {
  const navigate = useNavigate();
  const [registrationError, setRegistrationError] = useState(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const isValidEmail = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPassword = password => password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);

  const sanitizeInput = (input) => {
    // Remove potentially dangerous characters
    return input.replace(/[<>{}]/g, '');
  };

  const handleInputChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    setFormData({ ...formData, [e.target.name]: sanitizedValue });
  };

  const handleRegistrationSubmit = async (e) => {
    e.preventDefault();

    if (!isValidEmail(formData.email)) {
      setRegistrationError('Invalid email format');
      return;
    }

    if (!isValidPassword(formData.password)) {
      setRegistrationError('Password must be at least 8 characters, and include a number and a capital letter');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setRegistrationError('Passwords do not match');
      return;
    }

    const userData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      password: formData.password,
      sensors: [
        "c89127ff968251e2b1d642ef7074dc136c561f5f747781b98a8ed6ba26118738",
        "195aa885d7e8e11b5b5bea686eb2dd8dc1c391ccc1f938b59dbd4c97582590b0"
      ]
    };

    const registrationResponse = await onRegister(userData);

    if (registrationResponse.error) {
      setRegistrationError(registrationResponse.error);
      console.log('Registration failed:', registrationResponse.error);
    } else {
      // Handle successful registration
      console.log('Registration successful:', registrationResponse.data);
      navigate('/dashboard', { state: { userData: registrationResponse.data } });
    }
  };

  return (
    <div className='register'>
      <div className='register-form-layout'>
        <div className='register-form-container'>
          <div className='register-divider'>
            <p className='sign-up-txt'><span>Sign up</span></p>
          </div>
          <p className='explain-txt'>Get notified when beta is available.</p>
          {registrationError && (<div className="error-message">{registrationError}</div>)}
          <form onSubmit={handleRegistrationSubmit}>
            <input type='text' name='first_name' placeholder='First name' required onChange={handleInputChange} />
            <input type='text' name='last_name' placeholder='Last name' required onChange={handleInputChange} />
            <input type='text' name='email' placeholder='Email' required onChange={handleInputChange} />
            <input type='password' name='password' placeholder='Password' required onChange={handleInputChange} />
            <input type='password' name='confirmPassword' placeholder='Confirm Password' required onChange={handleInputChange} />
            <button className='create-account' type='submit'>Sign up</button>
          </form>
          <div className='register-form-footer'>
            <p>
              By signing up, you agree to our
              <span className='primary-color'>
                <Link to='/terms'> Terms of Service</Link>, <Link to='/privacy'> Privacy Policy </Link>
              </span>
              and
              <span className='primary-color'>
                <Link to='/privacy'> Cookies Policy</Link>
              </span>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
