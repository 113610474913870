// Hero.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Hero.css';

const Hero = ({ userData }) => {
  
  const navigate = useNavigate();

  return (
    <div className="hero">
      <div className='container'>
        <div className='content'>
          <div className='col-1'>
            <h1>Data collection </h1>
            <h1>
              <span className='primary-color'>reimagined.</span>
            </h1>
            <p>
              Intelligent Sensing Labs is designing a next-generation, connected sensor suite that simplifies data
              collection and analytics, turbocharging hardware teams and allowing engineers to focus on designing
              products, not test equipment.
            </p>
            <button className='learn-more-btn' onClick={() => navigate('/products')}>Learn More</button>
          </div>
          <div className='col-2'>
            <div className='welcome-layout'>
              <h1>Welcome, {userData.first_name}!</h1>
              <button onClick={() => navigate('/dashboard', { state: { userData: userData } })}>Go to Dashboard</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
