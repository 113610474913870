import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';

const ChangePassword = ({ onChangePassword, userData }) => {
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState(null);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });

  const isValidPassword = password => password.length >= 8 && /[A-Z]/.test(password) && /\d/.test(password);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePasswordChangeSubmit = async (e) => {
    e.preventDefault();

    if (!isValidPassword(formData.newPassword)) {
      setPasswordError('New password must be at least 8 characters, and include a number and a capital letter');
      return;
    }

    if (formData.newPassword !== formData.confirmNewPassword) {
      setPasswordError('New passwords do not match');
      return;
    }

    const passwordChangeResponse = await onChangePassword({
      currentPassword: formData.currentPassword,
      newPassword: formData.newPassword,
      email: userData.email
    });

    if (passwordChangeResponse.error) {
      setPasswordError(passwordChangeResponse.error);
      console.log('Password change failed:', passwordChangeResponse.error);
    } else {
      window.alert('Password changed successfully');
      navigate('/profile');
    }
  };

  return (
    <div className='change-password'>
      <div className='change-password-form-layout'>
        <div className='change-password-form-container'>
          <div className='change-password-divider'>
            <p className='change-password-txt'><span>Change Password</span></p>
          </div>
          {passwordError && (<div className="error-message">{passwordError}</div>)}
          <form onSubmit={handlePasswordChangeSubmit}>
            <input type='password' name='currentPassword' placeholder='Current Password' required onChange={handleInputChange} />
            <input type='password' name='newPassword' placeholder='New Password' required onChange={handleInputChange} />
            <input type='password' name='confirmNewPassword' placeholder='Confirm New Password' required onChange={handleInputChange} />
            <button className='submit-change' type='submit'>Change Password</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
