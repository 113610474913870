import React from 'react';
import './CookieWarning.css';

const CookieWarning = ({ onAccept }) => {
  return (
    <div className="cookie-warning">
      <p>We use cookies to improve your experience. By continuing, you agree to our use of cookies.</p>
      <button onClick={onAccept}>Accept</button>
    </div>
  );
};

export default CookieWarning;
