import React, { useEffect, useState } from 'react';
import './EventManager.css'

const EventManager = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const eventSource = new EventSource('https://intelligentsensinglabs.com/events/test');

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setMessages([data.message]);
    };

    eventSource.onerror = (error) => {
      console.error('Event error:', error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, []);

  return (
    <div id="event-data" className="server-time">
      <h2>Local Server Time</h2>
      {messages.map((message) => (
        <p>{message}</p>
      ))}
    </div>
  );
};

export default EventManager;