import React from 'react';
import { useNavigate } from 'react-router-dom';
import Hub from './/images/hub.png'
import Dash from './/images/Chart.png'
import './Products.css';
import { FaTemperatureHigh, FaTint, FaTachometerAlt, FaWeight,FaBatteryThreeQuarters, FaBolt, FaChartLine, FaReact, FaWrench, FaHistory} from 'react-icons/fa';

const Products = () => {
    const navigate = useNavigate();
    return (
        <div className="products">
            <div className="products-section">
                <div className="products-carousel">
                <h1>Sense One</h1>
                <h2>A modular sensing ecosystem designed to make data collection simple.</h2>
                    <div className="products-card">
                        <div>
                            <h2>Hub</h2>
                            <p>Auto <span className='primary-color'>magically</span> collect and save data from any sensor in range, including:</p>
                            <div className="products-container-centered">
                                <div className="products-tile">
                                    <ul>
                                        <li><FaTemperatureHigh /> Temperature</li>
                                        <li><FaWeight /> Pressure</li>
                                        <li><FaTint /> Humidity</li>
                                    </ul>
                                </div>                       
                                <div className="products-tile">
                                    <ul>
                                        <li><FaTachometerAlt /> Acceleration</li>
                                    </ul>
                                </div>
                                <div className="products-tile">
                                    <ul>
                                        <li><FaBolt /> Voltage</li>
                                        <li><FaBatteryThreeQuarters /> Current</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div>
                            <img src={Hub} alt='Hub' width="350" height="200" />
                        </div>
                    </div>
                    <div className="products-card">
                            <div>
                                <h2>Dashboard</h2>
                                <ul>
                                    <li><FaChartLine />  Instantly create sharp <span className='primary-color'>data visualizations.</span></li>
                                    <li><FaHistory />  Easily find old data with our unique <span className='primary-color'>timeline UI.</span></li>
                                    <li><FaReact />  Effortlessly write reports with our <span className='primary-color'>AI assistant.</span></li>
                                    <li><FaWrench /> Remotely control devices with <span className='primary-color'>web access.</span></li>
                                </ul>
                                <br></br>
                                <ul> 
                                    <li><b>All right from your browser.</b></li>
                                </ul>
                            </div>
                            <div className="products-container-centered">
                                <img className="screenshot" src={Dash} alt="Dashboard" width="600" />
                            </div>
                    </div>
                    <div className="signup-card">
                            <p> Sign up to get notified when beta hardware becomes available. </p>
                            <button onClick={() => navigate('/register')}> Sign up </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;
