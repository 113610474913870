import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { getUserIdentifier } from './cookieUtils';
import Logo from './images/isl_web_logo.png';
import './Navbar.css';

const Navbar = ({ userData, setUserData }) => {
    const [click, setClick] = useState(false);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    const handleSignOut = () => {
      const userIdentifier = getUserIdentifier();
      setUserData(null);
      localStorage.removeItem(userIdentifier + '_sensorCache');
      closeMobileMenu();
    };

    return (
        <div className='navbar'>
            <div className='logo'>
            {
                userData
                ? <Link to='/hero'><img src={Logo} alt='logo' onClick={closeMobileMenu} /></Link>
                : <Link to='/'><img src={Logo} alt='logo' onClick={closeMobileMenu} /></Link>
            }
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                {
                    userData
                    ? <li className='nav-item'><Link to='/hero' onClick={closeMobileMenu}>Home</Link></li>
                    : <li className='nav-item'><Link to='/' onClick={closeMobileMenu}>Home</Link></li>
                }
                <li className='nav-item'><Link to='/products' onClick={closeMobileMenu}>Products</Link></li>
                {
                    userData
                    ? <li className='nav-item'>
                    <Link to={{
                                pathname: '/dashboard',
                                state: userData
                            }} onClick={closeMobileMenu}>Dashboard</Link>
                    </li>
                    : <li></li>
                }
                {
                    userData
                    ? <li className='nav-item'><Link to='/profile' onClick={closeMobileMenu}>Profile</Link></li>
                    : <li></li>
                }
                {
                    userData 
                    ? <li className='nav-item'><Link to='/' onClick={handleSignOut}>Logout</Link></li>
                    : <li className='nav-item'><Link to='/register' onClick={closeMobileMenu}>Register</Link></li>
                }
            </ul>
            <div className='hamburger' onClick={handleClick}>
                {click ? (<FaTimes size={30} style={{ color: '#22262a' }}/>) : (<FaBars size={30} style={{ color: '#22262a' }} />)}
            </div>
        </div>
    );
};

export default Navbar;
