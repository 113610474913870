import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer'>
            <ul className='footer-menu'>
                <li className='footer-item'> © 2023 Intelligent Sensing Labs, LLC. All rights reserved. </li>
                <li className='footer-item'><Link to='/terms'>Terms of Service</Link></li>
                <li className='footer-item'><Link to='/privacy'>Privacy Policy</Link></li>
            </ul>
        </div>
    )
}

export default Footer