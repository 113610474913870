import React from 'react';
import { Link } from 'react-router-dom'; // Import Link
import './Profile.css';

const Profile = ({ userData }) => {
  let { first_name, last_name, email, sensors } = userData;

  return (
    <div className='profile'>
      <div className='profile-form-layout'>
        <div className='profile-form-container'>
          <div className='profile-picture'>
            <img src='https://via.placeholder.com/150' alt='Profile' />
          </div>
          <div className='info-container'>
            <div className='info-row'>
              <span className='info-title'>First Name:</span>
              <span className='info-content'>{first_name}</span>
            </div>
            <div className='info-row'>
              <span className='info-title'>Last Name:</span>
              <span className='info-content'>{last_name}</span>
            </div>
            <div className='info-row'>
              <span className='info-title'>E-mail:</span>
              <span className='info-content'>{email}</span>
            </div>
            <div className='info-row'>
              <span className='info-title'>Hubs:</span>
              <ul className='info-content'>
                {sensors.map((sensor, index) => (
                  <li key={index}>{sensor}</li>
                ))}
              </ul>
            </div>
              <Link to='/changepassword'>
                <button className='change-password-button'>Change Password</button>
              </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
